


























































import { Vue, Component } from "vue-property-decorator";
import { CarouselItem } from "@/types/components/carousel";
import Carousel from "@/components/Carousel.vue";
import axios, { APIResponse } from "@/plugins/axios";
import AlertModule, { AlertType } from "@/store/modules/alert";
import { CompanyNews } from "@/types/components/news";
import ServiceCardneues2022 from "@/components/company/services/ServiceCardneues2022.vue";

@Component({
    components: {
        Carousel,
        ServiceCardneues2022,
    },
})
export default class News extends Vue {
    private companyNews: CompanyNews[] = [];

    private mounted() {
        if (!this.$route.hash) return;

        const el = document.querySelector(this.$route.hash);
        if (!el) return;
        el.scrollIntoView();
    }

    private carouselItems: CarouselItem[] = [
        {
            imageSrc: require("@/assets/img/carousel/newyear2022.png"),
            heading: "Herzlich Willkommen in 2022!",
            subheading:
                "Wir wünschen Ihnen ein erfolgreiches Jahr und stets viel Gesundheit. In 2022 starten wir mit alten stärken und neuen Kompetenzen.",
            button: null,
        },
        {
            imageSrc: require("@/assets/img/carousel/adflyer/slider-iquadrat.png"),
            heading: "Jetzt ist Schluss mit dem Papierkram",
            subheading:
                "Dank unserer Partnerschaft mit iQuadrat können wir Ihnen die passende Komplettlösung für Ihr digitales Dokumentenmanagement bieten.",
            button: null,
        },
        {
            imageSrc: require("@/assets/img/carousel/adflyer/slider-managed_services.png"),
            heading: "skiba Managed Services",
            subheading:
                "Systemausfälle vorhersehen, bevor sie passieren. Mit unseren skiba Managed Services haben wir all Ihre Systeme im Blick und greifen ein, bevor es zu spät ist!",
            button: null,
        },
        {
            imageSrc: require("@/assets/img/carousel/adflyer/slider-codetwo.png"),
            heading: "CodeTwo",
            subheading:
                "Jetzt deine individuelle Firmen eMail-Signaturen mit Werbebanner sichern!",
            button: null,
        },
        {
            imageSrc: require("@/assets/img/carousel/adflyer/slider-microsoft_partner.png"),
            heading: "Microsoft Partner",
            subheading:
                "Neben den klassischen Office-Produkten ermöglicht Microsoft Teams mit Microsoft Sharepoint einen optimalen Workflow dank Videotelefonie und geteilten Inhalten über ein intelligentes Intranet.",
            button: null,
        },
        {
            imageSrc: require("@/assets/img/carousel/adflyer/slider-zoom.png"),
            heading: "Jeder Raum kann ein Konferenzraum sein",
            subheading:
                "Dank Zoom Meetings und Coworking kannst du jederzeit und von überall gemeinsam mit deinen Kollegen im Team arbeiten.",
            button: null,
        },
    ];

    private async created() {
        try {
            this.companyNews = await this.fetchCompanyNews();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    private async fetchCompanyNews(): Promise<CompanyNews[]> {
        const response = (
            await axios.get<APIResponse<CompanyNews[]>>("/blog/articles")
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknowError");
        }
        return response.data;
    }
}
