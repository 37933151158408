































































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ServiceCard extends Vue {
    @Prop({ type: String, required: true }) readonly link!: string;
    @Prop({ type: String, required: true }) readonly title!: string;
    @Prop({ type: String, required: true }) readonly imageSrc!: string;
    @Prop({ type: String, default: "top" }) readonly imagePosition!: string;
}
